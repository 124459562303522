/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import TeamMember from "./TeamMember";
import PropTypes from "prop-types";
import { getAuthors } from "../../helper/api";
import "./team.m.css";
import { COMPRESS_PARAMS_MEDIUM } from "../../../image-constants";

// TODO: Make the Departments dynamic
const DEPARTMENTS = [
  "Production",
  "Editorial",
  "Revenue",
  "Design",
  "Accounts",
  "Support Staff",
  "Social Media",
  "Product",
  "Team composition and Org Chart",
];

const idToSkip = [1758180, 1880925];

const TheOrgChart = () => {
  return (
    <div styleName="full-grid">
      <iframe
        src="https://theorg.com/org/newslaundry/embed"
        width="100%"
        height="800px"
        style={{ border: "1px solid #eaeaeb", borderRadius: "4px" }}
      ></iframe>
    </div>
  );
};

const DepartmentTab = ({ department, handleTabSelect, isSelected }) => {
  return (
    <>
      <button
        styleName={`tab ${isSelected ? "is-selected" : ""}`}
        onClick={() => handleTabSelect(department)}
      >
        {department}
      </button>
    </>
  );
};

export function TeamPage() {
  const [selectedTab, setSelectedTab] = useState("All");
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [memberData, setMemberData] = useState([]);
  // const [departments, setDepartments] = useState(["All"]);

  useEffect(() => {
    const getAuthorsData = async () => {
      try {
        const { authors } = await getAuthors();

        const filterAuthor = authors
          .filter(
            (author) =>
              (author.metadata.department || author.metadata.designation) &&
              !idToSkip.includes(author.id)
          )
          .map((author) => {
            if (author.metadata.department.includes("and")) {
              return {
                ...author,
                metadata: {
                  ...author.metadata,
                  department: author.metadata.department
                    .split("and")
                    .map((dep) => dep.trim()),
                },
              };
            } else {
              return author;
            }
          })
          .sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }

            return -1;
          });

        setSelectedAuthors(filterAuthor);
        setMemberData(filterAuthor);
      } catch (error) {
        console.error(`Author Error : ${error}`);
      }
    };

    getAuthorsData();
  }, []);

  // useEffect(() => {
  //   if (selectedAuthors.length > 0) {
  //     selectedAuthors.forEach(author => {
  //       if (!departments.includes(author.metadata.department)) {
  //         setDepartments([...departments, author.metadata.department]);
  //       }
  //     });
  //   }
  // }, [selectedAuthors]);

  useEffect(() => {
    if (selectedTab === "All") {
      setMemberData(selectedAuthors);
    }
    // else if (selectedTab === "Team composition and Org Chart") {
    // }
    else {
      const filteredMembers = selectedAuthors.filter((author) => {
        if (typeof author.metadata.department === "object") {
          let resp = false;

          if (author.metadata.department.includes(selectedTab)) {
            resp = true;
          }

          return resp;
        } else {
          let resp = false;

          if (author.metadata.department === selectedTab) {
            resp = true;
          }

          return resp;
        }
      });

      setMemberData(filteredMembers);
    }
  }, [selectedTab]);

  const handleTabSelect = (tab) => setSelectedTab(tab);

  return (
    <section styleName="section">
      <main styleName="hero-section">
        <div>
          <div styleName="hero-section-content">
            <h1 styleName="title">Meet the team</h1>
            <p styleName="border-bottom"></p>
            <p styleName="description">
              We are a team of young and experienced minds who are primarily
              driven by the need to build a news organisation that readers can
              take pride in.
            </p>
            <p styleName="description">
              In an industry driven by corporate and government interests, we
              strongly believe in the need for an independent news model, and a
              free and accountable press.
            </p>
          </div>
          <div styleName="hero-section-img">
            <img
              src={`https://images.assettype.com/newslaundry/2022-03/b17c8bf8-5660-4993-8c74-9ca7190c5e6d/team_page.png${COMPRESS_PARAMS_MEDIUM}`}
              alt="Team Hero Image"
            />
          </div>
        </div>
      </main>
      <div styleName="sub-section">
        <div styleName="section-wrapper">
          <div styleName="departments-wrapper">
            <DepartmentTab
              department={"All"}
              handleTabSelect={handleTabSelect}
              isSelected={selectedTab === "All"}
            />
            {DEPARTMENTS.sort().map((department) => (
              <DepartmentTab
                key={department}
                department={department}
                handleTabSelect={handleTabSelect}
                isSelected={selectedTab === department}
              />
            ))}
          </div>
          <div styleName="team-wrapper">
            {selectedTab === "Team composition and Org Chart" ? (
              <TheOrgChart />
            ) : (
              memberData.map((member, i) => {
                return <TeamMember member={member} key={i} />;
              })
            )}
          </div>
        </div>
      </div>
      <div styleName="sub-section">
        <div styleName="composition-wrapper">
          <div styleName="title-wrapper">
            <h2 styleName="title">Team Composition</h2>
            <p styleName="border-bottom"></p>
          </div>
          <p styleName="description">
            At <i>Newslaundry</i>, we believe in the importance of transparency
            for a diverse and representative organisation. Here is a break-up of
            caste, gender, faith and sexuality across the team.
          </p>
          <div styleName="composition-grid">
            <iframe
              src="https://flo.uri.sh/visualisation/9294145/embed"
              title="Interactive or visual content"
              className="flourish-embed-iframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "400px" }}
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
            ></iframe>
            <iframe
              src="https://flo.uri.sh/visualisation/9296611/embed"
              title="Interactive or visual content"
              className="flourish-embed-iframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "400px" }}
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
            ></iframe>
            <iframe
              src="https://flo.uri.sh/visualisation/9296621/embed"
              title="Interactive or visual content"
              className="flourish-embed-iframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "400px" }}
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
            ></iframe>
            <iframe
              src="https://flo.uri.sh/visualisation/9296605/embed"
              title="Interactive or visual content"
              className="flourish-embed-iframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "400px" }}
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
            ></iframe>
            <iframe
              src="https://flo.uri.sh/visualisation/9296625/embed"
              title="Interactive or visual content"
              className="flourish-embed-iframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "400px" }}
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
            ></iframe>
          </div>
          <h3 styleName="sub-title">Team Leads</h3>
          <div styleName="composition-grid">
            <iframe
              src="https://flo.uri.sh/visualisation/9298700/embed"
              title="Interactive or visual content"
              className="flourish-embed-iframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "400px" }}
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
            ></iframe>
            <iframe
              src="https://flo.uri.sh/visualisation/9298775/embed"
              title="Interactive or visual content"
              className="flourish-embed-iframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "400px" }}
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
            ></iframe>
            <iframe
              src="https://flo.uri.sh/visualisation/9298795/embed"
              title="Interactive or visual content"
              className="flourish-embed-iframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "400px" }}
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
            ></iframe>
            <iframe
              src="https://flo.uri.sh/visualisation/9298764/embed"
              title="Interactive or visual content"
              className="flourish-embed-iframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "400px" }}
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
            ></iframe>
            <iframe
              src="https://flo.uri.sh/visualisation/9461880/embed"
              title="Interactive or visual content"
              className="flourish-embed-iframe"
              frameBorder="0"
              scrolling="no"
              style={{ width: "100%", height: "400px" }}
              sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

DepartmentTab.propTypes = {
  department: PropTypes.string,
  handleTabSelect: PropTypes.func,
  isSelected: PropTypes.bool,
};
